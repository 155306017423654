import React, { useEffect, useState } from 'react';
import { useAppContext } from '@/fe-hooks';
import { AppContextType } from '@/fe-common/types/contexts';
import { Footer as FooterComponent } from '@digsup/components-footer';

type FooterProps = {
  links?: Array<any>;
};

const defaultLinks = [
  {
    href: '/',
    name: 'About',
  },
  {
    href: '/',
    name: 'Careers',
  },
  {
    href: 'https://app.termly.io/document/terms-of-use-for-saas/00e9ad1e-22f6-478e-b4d4-f426fdee2c37',
    name: 'Terms',
  },
  {
    href: 'https://app.termly.io/document/privacy-policy/9276ef06-a5e3-4c1b-b01d-cebd004a8180',
    name: 'Privacy',
  },
];

export const Footer = ({ links = defaultLinks }: FooterProps) => {
  const { userId }: AppContextType = useAppContext();
  const [footerInitialized, setFooterInitialized] = useState(false);
  const hasSupport = defaultLinks.some(x => x.name === 'Support');
  if (userId && !hasSupport) {
    links.push({
      href: 'mailto:support@digsup.com?subject=DigsUp%20Support%20Request',
      name: 'Support',
    });
  }

  const footer = footerInitialized ? (
    <FooterComponent links={links} />
  ) : null;

  useEffect(() => {
    if (!footerInitialized) {
      setFooterInitialized(true);
    }
  }, []);

  return footer;
};
